import { TFunction } from "i18next";
import * as yup from "yup";

export const orderTicketsSchema = (errorsT: TFunction) =>
  yup.object({
    ticketsCount: yup
      .number()
      .min(0, errorsT("number.min", { min: 0 }))
      .required(errorsT("required"))
  });
