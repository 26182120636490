import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useApiClient } from "@services/Api/ApiClientContext";
import { useEventsModals } from "@app/panel/Events/contexts/EventsModals.context";

import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Modal from "@components/overlay/Modal/Modal";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Input from "@components/dataEntry/Input/Input";
import Button from "@components/dataEntry/Buttons/Button/Button";

import { orderTicketsSchema } from "./orderTickets.schema";
import "./OrderTickets.scss";

interface OrderTicketsForm {
  ticketsCount: number;
}

const OrderTickets = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { eventId } = useParams();

  const { orderTickets } = useApiClient();
  const { closeModal } = useEventsModals();

  const { t } = useTranslation(["eventsModals", "common"]);
  const { t: errorsT } = useTranslation("formErrors");

  const validationSchema = orderTicketsSchema(errorsT);

  const handleOrderTickets = ({ ticketsCount }: OrderTicketsForm) => {
    setSubmitting(true);

    orderTickets(eventId || "", ticketsCount)
      .then((res) => {
        if (res?.status === 204) {
          closeModal();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik<OrderTicketsForm>({
    initialValues: {
      ticketsCount: 0
    },
    validationSchema,
    onSubmit: handleOrderTickets
  });

  return (
    <Portal>
      <Backdrop open>
        <Modal onClose={closeModal}>
          <Stack className="order-tickets-modal">
            <h2>{t("eventsModals:orderTickets.title")}</h2>
            <p className="p0 mt-4 mb-4">{t("eventsModals:orderTickets.description")}</p>

            <Input
              name="ticketsCount"
              type="number"
              label={t("eventsModals:orderTickets.inputs.ticketsCount.label")}
              placeholder={t("eventsModals:orderTickets.inputs.ticketsCount.placeholder")}
              minValue={0}
              step={1}
              value={values.ticketsCount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.ticketsCount && touched.ticketsCount ? errors.ticketsCount : ""}
              className="order-tickets-modal-input"
              required
            />

            <Group colGap={20} className="mt-5">
              <Button type="submit" variant="primary" onClick={handleSubmit} loading={submitting}>
                {t("common:order")}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                {t("common:cancel")}
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default OrderTickets;
